<!-- 解决方案 公共副标题 -->
<template>
  <div class="subhead">
    <h3 :class="{ 'blue': isBlue }">{{ enVal }}</h3>
    <h3 :class="{ 'blue': isBlue }">{{ znVal }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    enVal: {
      type: String,
      default: "Subhead",
    },
    znVal: {
      type: String,
      default: "中文标题"
    },
    isBlue: {  // 0默认颜色, 1 是蓝色标题
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="less" scoped>
.subhead {
  h3 {
    height: 32px;
    font-size: 24px;
    font-weight: bold;
    color: #525252;
    line-height: 32px;

    &:first-child {
      margin-bottom: 11px;
    }
  }

  .blue {
    color: #2A5CE9;
  }

}
</style>