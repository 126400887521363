<!-- 解决方案 顶部大图 公共组件 -->
<template>
  <div class="img">
    <img :src="bannerImg" alt="">
    <div class="title-container">
      <div class="zn">{{ bannerTitleData.zn }}</div>
      <div class="en">{{ bannerTitleData.en }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerImg: {
      type: String,
      default: ''
    },
    bannerTitleData: {
      type: Object,
      default: {
        zn: "",
        en: ""
      }
    }
  }
}
</script>

<style lang="less" scoped>
.img {
  position: relative;
  max-width: 1920px;
  // height: 712px;
  // background-color: skyblue;
  margin: 0 auto;
  object-fit: cover;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .title-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 827px;
    height: 232px;
    background: rgba(42, 92, 233, .7);
    // padding-left: 262px;
    padding: 61px 0 61px 262px;
    box-sizing: border-box;

    .zn {
      height: 56px;
      font-size: 40px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 56px;
    }

    .en {
      height: 45px;
      font-size: 32px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 45px;
    }


  }
}
</style>